import * as React from 'react';
import styles from './header.scss';
import ListSvg from './images/list';
import EnSvg from './images/en';
import ZhSvg from './images/zh';
import Zh from '../locales/zh-CN.json';
import En from '../locales/en-US.json';
import aminerLogo from './images/aminer_logo.png';
import ckLogo from './images/ckcest_home.png';

type TabType = "map" | "data" | "kg" | "timeline" | "scholars" | "policy" | "contributors" | "opensource" | "more";

interface IProps {
    lang: "zh" | "en";
    tab?: TabType;
    onSwitchLang: () => void;
}

interface IState {
    showList: boolean;
    tabHover: string;
    subtabX: number;
}

export default class Header extends React.Component<IProps, IState> {
    private _tools: HTMLDivElement | null = null;
    private _research: HTMLDivElement | null = null;
    private _public: HTMLDivElement | null = null;
    private _subtabTimer: any = null;
    constructor(props: IProps) {
        super(props);
        this.state = {
            showList: false,
            tabHover: "",
            subtabX: 0
        };

        this.handleSelftest = this.handleSelftest.bind(this);
        this.handleTabHover = this.handleTabHover.bind(this);
        this.handleLangClick = this.handleLangClick.bind(this);
        this.clearTimer = this.clearTimer.bind(this);
        this.handleTabOut = this.handleTabOut.bind(this);
        this.handleDatasets = this.handleDatasets.bind(this);
        this.handleKg = this.handleKg.bind(this);
        this.handlePolicy = this.handlePolicy.bind(this);
        this.handleTimeline = this.handleTimeline.bind(this);
        this.handleExperts = this.handleExperts.bind(this);
        this.handleEf = this.handleEf.bind(this);
        this.fm = this.fm.bind(this);
        this.handleMap = this.handleMap.bind(this);
        this.handleMore = this.handleMore.bind(this);
        this.handleContributors = this.handleContributors.bind(this);
        this.handleGithub = this.handleGithub.bind(this);
        this.renderTab = this.renderTab.bind(this);
        this.renderMTab = this.renderMTab.bind(this);
    }

    private handleSelftest() {
        let url: string =
          this.props.lang == "zh"
            ? "http://covid-19-zh.tsing-care.com"
            : "https://covid-19-en.tsing-care.com/";
        window.open(url, "_blank");
    }

    private fm(id: string): string {
        let obj = this.props.lang == 'zh' ? Zh : En;
        return obj[id] || id;
    }

    private handleTabHover(tab: string) {
        if(!tab) {
            this.setState({tabHover: ""});
        }else if(tab == "tools") {
            this.clearTimer();
            let subtabX: number = this._tools!.offsetLeft + this._tools!.parentElement!.offsetLeft - this._tools!.clientWidth/2 + 16;
            this.setState({tabHover: tab, subtabX});
        }else if(tab == "research") {
            this.clearTimer();
            let subtabX: number = this._research!.offsetLeft + this._research!.parentElement!.offsetLeft - this._research!.clientWidth/2 + 62;
            this.setState({tabHover: tab, subtabX});
        }else if(tab == "public") {
            this.clearTimer();
            let subtabX: number = this._public!.offsetLeft + this._public!.parentElement!.offsetLeft - this._public!.clientWidth/2 + 52;
            this.setState({tabHover: tab, subtabX});
        }
    }

    private clearTimer() {
        this._subtabTimer && clearTimeout(this._subtabTimer);
    }

    private handleTabOut() {
        this._subtabTimer = setTimeout(() => {
            this.handleTabHover("");
        }, 500)
    }

    private handleLangClick() {
        this.props.onSwitchLang && this.props.onSwitchLang();
        if(this.state.showList) {
            this.setState({showList: false});
        }
    }

    private handleDatasets() {
        window.open("https://aminer.cn/data-covid19/?lang=" + this.props.lang, '_blank');
    }

    private handleKg() {
        window.open("https://covid-19.aminer.cn/kg/?lang=" + this.props.lang, "_blank");
    }

    private handleMore() {
        window.open("https://covid-19.aminer.cn/?lang=" + this.props.lang, "_blank");
    }

    private handlePolicy() {
        window.open("https://zhengce.aminer.cn/", "_blank");
    }

    private handleTimeline() {
        window.open("https://www.aminer.cn/ncp-pubs?lang=" + this.props.lang, "_blank");
    }

    private handleExperts() {
        window.open("https://2019-ncov.aminer.cn", "_blank");
    }

    private handleEf() {
        if(this.props.lang == 'zh') {
            window.open('http://covid19-report.com/#/forecasting_cn', "_blank");
        }else {
            window.open('http://covid19-report.com/#/', "_blank");
        }
    }

    private handleMap() {
        window.open("https://covid-dashboard.aminer.cn?lang=" + this.props.lang, "_blank");
    }

    private handleContributors() {
        window.open("https://covid-dashboard.aminer.cn/contributors/?lang=" + this.props.lang, "_blank");
    }

    private handleGithub() {
        window.open("https://github.com/AMinerOpen/covid_dashboard", "_blank");
    }

    private renderTab(label: TabType, textId: string, onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void): JSX.Element {
        const { tab } = this.props;
        return (
            <div className={styles.tab} style={tab == label ? {color: '#ebc543'} : undefined} onClick={tab == label ? undefined : onClick}>{this.fm(textId)}</div>
        )
    }

    private renderMTab(label: TabType, textId: string, onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void): JSX.Element {
        const { tab } = this.props;
        return (
            <div className={styles.item} style={tab == label ? {color: '#ebc543'} : undefined} onClick={tab == label ? undefined : onClick}>{this.fm(textId)}</div>
        )
    }

    public render() {
        const {lang} = this.props;
        return (
            <div className={styles.header}>
                <div className={styles.logo}>
                    <div className={styles.logo1}><a href='http://www.ckcest.cn/' target='_blank'><img src={ckLogo} /></a></div>
                    <div className={styles.line1} />
                    <div className={styles.logo2}><a href='https://www.aminer.cn/' target='_blank'><img src={aminerLogo} /></a></div>
                    {/* <div className={styles.line2} />
                    <div className={styles.map_logo} onClick={this.handleMap}>
                        {
                            this.props.lang == 'zh' ? (
                            <div>
                                <div className={styles.logo_up}>知识疫图</div>
                                <div className={styles.logo_down}>COVID-19 GRAPH</div>
                            </div>
                            ) : (
                                <div className={styles.logo_en}>COVID-19 GRAPH</div>
                            )
                        }
                    </div> */}
                </div>
                <div className={styles.tabs}>
                    <div className={styles.tabs_inner}>
                        { this.renderTab("map", "header.map", this.handleMap) }
                        { this.renderTab("data", "header.datasets", this.handleDatasets) }
                        { this.renderTab("kg", "header.kg", this.handleKg) }
                        { this.renderTab("timeline", "header.timeline", this.handleTimeline) }
                        { this.renderTab("scholars", "header.experts", this.handleExperts) }
                        { this.renderTab("policy", "header.policy", this.handlePolicy) }
                        { this.renderTab("contributors", "header.contributors", this.handleContributors) }
                        { this.renderTab("opensource", "header.opensource", this.handleGithub) }
                        { this.renderTab("more", "header.more", this.handleMore) }
                    </div>
                </div>
                <div className={styles.menu} onClick={() => this.setState({showList: !this.state.showList})} ><ListSvg /></div>
                {
                    this.state.showList && (
                        <div className={styles.list}>
                            { this.renderMTab("map", "header.map", this.handleMap) }
                            { this.renderMTab("data", "header.datasets", this.handleDatasets) }
                            { this.renderMTab("kg", "header.kg", this.handleKg) }
                            { this.renderMTab("timeline", "header.timeline", this.handleTimeline) }
                            { this.renderMTab("scholars", "header.experts", this.handleExperts) }
                            { this.renderMTab("policy", "header.policy", this.handlePolicy) }
                            { this.renderMTab("contributors", "header.contributors", this.handleContributors) }
                            { this.renderMTab("opensource", "header.opensource", this.handleGithub) }
                            { this.renderMTab("more", "header.more", this.handleMore) }
                            <div className={styles.item} onClick={this.handleLangClick}>{lang == 'zh' ? "English" : "中文"}</div>
                        </div>
                    )
                }
                <div className={styles.lang} onClick={this.handleLangClick}>
                    { lang == 'zh' ? <ZhSvg /> : <EnSvg />}
                </div>
            </div>
        )
    }
}